import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import '../../assets/sass/scheduler.scss';
import { Link } from 'gatsby';

const Notice = () => (
  <div className="row-no-gutters" fluid id="notice">
    <Row className={'notice-container'}>
      <div class="text-container" style={{ width: '90%' }}>
        <Col md={12} lg={12} className={'active'}>
        <Link to="/offer">
          <span style={{ color: '#fff' }}>Set Someone Free this holiday season.</span>
          <span>&nbsp;
            <span style={{ borderBottom: '1px solid #707070' }}>
              Click here
            </span>
            &nbsp; for more information
          </span>
          </Link>
        </Col>
      </div>
      {/* <div class="text-container">
        <Col md={1} lg={1} className={'active'}>
          <a
            onClick={() => {
              document.getElementById('notice').classList.add('hide');
              console.log('hide');
            }}
            class="btnForm"
          >
            <span>
              <i class="fa fa-close fa-1x" style={{ cursor: 'pointer' }}></i>
            </span>
          </a>
        </Col>
      </div> */}
    </Row>
  </div>
);

export default Notice;
