import 'date-fns';
import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';

const styles = {
  fontFamily: "sans-serif",
  display: "flex",
  justifyContent:"start"
};

function DateTimePickerScheduler(props) {

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  useEffect(() => {
    // Props Update
   console.log(props.bookingSlot)
  },[]);

  const datepickeropen = () => {
    console.log('picker open')
    document.body.style.position  = 'fixed';
    var element = document.getElementById("scrollBody");
    element.classList.add("noscrollBody");
  };
  const datepickerClose = () =>{
    document.body.style.position  = 'relative';
    var element = document.getElementById("scrollBody");
    element.classList.remove("noscrollBody");
  }

  return (
    
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justify="space-around">
      <div className='bookingSlotRadio'>
      <DatePicker
        style={{width: '60% !important',marginRight:'20px'}}
        label="Please Select Date"
        inputVariant="outlined"
        value={props.onSchedulingTime}
        minDate={moment().format('yyyy-MM-DD')}
        format={"DD-MM-YYYY"}
        minutesStep={5}
        variant={"dialog"}
        onChange={props.onChangeTime}
        onOpen={datepickeropen}
        onClose={datepickerClose}
      />
      
      
          <button value={"08:00:00"} onClick={props.handleShiftChange} className={`btn square light btn btn-primary eightBooking ${props.bookingSlot === '08:00:00'? 'selected':'notSelected' }`}>8:00 AM</button>
          <button value={"11:30:00"} onClick={props.handleShiftChange} className={`btn square light btn btn-primary elevenBooking ${props.bookingSlot === '11:30:00'? 'selected':'notSelected' }`}>11:30 AM</button>
      </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DateTimePickerScheduler;
